// @ubo
import { Links, Scripts, LiveReload, AdminPanel, LosseLink, useLocation } from '@ubo/losse-sjedel'
import Island from '../elements/svg/Island'
import Lines from '../elements/svg/Lines'
import LogoLight from '../elements/svg/LogoLight'

export default function PageNotFound() {
  const location = useLocation()
  const locale = location.pathname.includes('/en/') ? 'en_US' : 'nl_NL'

  return (
    <html lang={locale === 'nl_NL' ? 'nl' : 'en'}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body>
        <div className="flex h-[1px] min-h-screen flex-col">
          <div className="relative flex h-full flex-1 flex-col">
            <div className="flex h-1/3 w-full bg-ubo-whiscash text-white lg:h-1/2">
              <div className="container py-8">
                <div className="flex justify-end">
                  <LosseLink to={locale === 'nl_NL' ? '/' : '/en/'}>
                    <LogoLight />
                  </LosseLink>
                </div>
                <div className="flex h-full items-end py-16">
                  <h1 className="text-stroke text-center font-september text-4xl font-black sm:text-7xl md:text-9xl lg:text-left lg:text-11xl 2xl:text-13xl">
                    {locale === 'nl_NL' ? 'DURF TE VERDWALEN' : 'DARE TO GET LOST'}
                  </h1>
                </div>
              </div>
            </div>
            <div className="relative h-2/3 w-full bg-ubo-taillow text-white lg:h-1/2">
              <div className="mx-auto h-4/6 max-w-[1536px] items-center bg-ubo-taillow py-5 lg:grid lg:h-full lg:grid-cols-3">
                <div className="col-span-3 mt-7 flex justify-center xl:order-last xl:col-span-1 xl:mt-0">
                  <Island className="h-[100px] w-auto lg:h-[200px] xl:-mt-72 xl:h-full" />
                </div>
                <div className="col-span-3 xl:order-first xl:col-span-2">
                  <div className="flex flex-col items-center px-4 xl:flex-row xl:items-start xl:px-12 2xl:px-28">
                    <div>
                      <h2 className="mr-11 mt-4 text-center font-september text-4xl font-black sm:text-6xl md:text-7xl lg:mt-0 lg:text-left lg:text-13xl">
                        404
                      </h2>
                      <div className="mt-7 flex items-center">
                        <LosseLink className="btn btn--light--filled mr-6 text-base" to={locale === 'nl_NL' ? '/' : '/en/'}>
                          Go Home
                        </LosseLink>
                        <LosseLink to={locale === 'nl_NL' ? '/contact/' : '/en/contact/'} className="btn btn--light text-base">
                          {locale === 'nl_NL' ? 'Neem contact op' : 'Contact us'}
                        </LosseLink>
                      </div>
                    </div>
                    <p className="ml-7 mt-7 font-september text-2xl font-black sm:text-3xl lg:text-4xl">
                      {locale === 'nl_NL'
                        ? 'DE PAGINA DIE JE ZOEKT KAN NIET WORDEN GEVONDEN'
                        : 'THE PAGE YOU ARE LOOKING FOR CANNOT BE FOUND'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 w-full">
              <Lines className="max-w-full lg:max-w-none" />
            </div>
          </div>
        </div>

        <Scripts />
        <LiveReload />
        <AdminPanel />
      </body>
    </html>
  )
}
