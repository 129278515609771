export default function Lines({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1728" height="46" fill="none" viewBox="0 0 1728 46" className={className}>
      <g strokeMiterlimit="10" clipPath="url(#clip0_2285_20213)" filter="url(#filter0_d_2285_20213)">
        <path
          stroke="#000CED"
          strokeWidth="2"
          d="M303.729 155.753L922.36-462.886M376.065 130.337l569.759-569.759M415.627 137.671l553.645-553.645M459.789 140.594l533.03-533.029M483.235 164.05l533.035-533.029M506.691 187.498l533.029-533.03M36.14 70.144l533.029-533.03M59.596 93.608l533.03-533.03M83.052 117.055l533.03-533.029M106.591 140.594l533.03-533.029M130.038 164.05l533.03-533.029M153.493 187.498l533.03-533.03"
        ></path>
        <path
          stroke="#070744"
          strokeWidth="3"
          d="M-301.577 70.144l533.03-533.03M-278.113 93.608l533.03-533.03M-254.665 117.055l533.03-533.029M-231.126 140.594l533.03-533.029M-207.671 164.05l533.03-533.029M-184.225 187.498l533.03-533.03"
        ></path>
        <path
          stroke="#000CED"
          strokeWidth="2"
          d="M662.993 148.717L1274.6-462.886M732.646 125.984l565.404-565.406M774.898 130.636l546.602-546.61M812.018 140.594l533.032-533.029M835.465 164.05l533.025-533.029M858.921 187.498l533.029-533.03M1021.52 143.086l605.98-605.972M1075.06 136.475l575.9-575.897M1123.22 135.212l551.19-551.186M1164.92 140.594l533.03-533.029M1188.37 164.05l533.03-533.029M1211.82 187.498l533.03-533.03M1371.19 147.389l605.97-605.981M1424.73 140.769l575.9-575.897M1472.89 139.515l551.19-551.195M1514.59 144.897l533.03-533.03M1538.04 168.345l533.03-533.03M1561.49 191.8l533.03-533.029"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_2285_20213"
          width="1739"
          height="54"
          x="-7"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2285_20213"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_2285_20213" result="shape"></feBlend>
        </filter>
        <clipPath id="clip0_2285_20213">
          <path fill="#fff" d="M0 0H1731V46H0z" transform="translate(-3)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
