import { Root, rootLoader as loader, useLoaderData, useLocation } from '@ubo/losse-sjedel';
import type { LinksFunction, RootProps } from '@ubo/losse-sjedel';
import PageNotFound from '~/components/layout/PageNotFound';
import styles from '~/global.css';
import FlexContent from './graphql/FlexContent';
import Queries from './graphql/Queries';
import PostTypes from './graphql/PostTypes';
import stylesProgress from 'nprogress/nprogress.css';

export const flexData = {
  flexContent: FlexContent,
  queries: Queries,
  postTypes: PostTypes
};

export const links: LinksFunction = () => {
  return [
  {
    href: 'https://use.typekit.net',
    rel: 'preconnect',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'preload',
    as: 'style',
    href: styles
  },
  {
    href: 'https://use.typekit.net/moh8rro.css',
    rel: 'preload',
    as: 'style'
  },
  {
    href: 'https://use.typekit.net/moh8rro.css',
    rel: 'stylesheet'
  },
  {
    href: stylesProgress,
    rel: 'stylesheet'
  },
  {
    href: styles,
    rel: 'stylesheet'
  },
  {
    href: '/manifest.json',
    rel: 'manifest'
  },
  {
    rel: 'icon',
    href: '/favicon.png',
    type: 'image/png'
  }];

};

export { loader };

export function ErrorBoundary() {
  return <PageNotFound />;
}

export default function App() {
  const data = useLoaderData<RootProps>();
  const location = useLocation();
  const locale = location.pathname.includes('/en/') ? 'en_US' : 'nl_NL';

  return <Root {...data} lang={locale === 'nl_NL' ? 'nl' : 'en'} />;
}